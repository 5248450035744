<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <!-- 좌측 메뉴 목록 컴포넌트 -->
        <buildTotalMenu :mgm-bld-pk="mgmBldPk" />
      </div>
      <div class="col-md-9" style="height: 600px;overflow-y: scroll">
        <!-- 메뉴 아이템 상세 정보 컴포넌트 -->
        <buildTotalContent :mgm-bld-pk="mgmBldPk" />
      </div>
    </div>
  </div>
</template>

<script>
import buildTotalMenu from './buildTotalMenu';
import buildTotalContent from './buildTotalContent';

export default {
  components: {
    buildTotalMenu,
    buildTotalContent,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
};
</script>
