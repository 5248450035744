<template>
  <div>
    <validation-observer ref="editForm">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="6">
            <b-form-group label="아이디 (이메일):" label-for="userId">
              <b-form-input
                id="userId"
                v-model="formData.userId"
                required
                style="height: 40px"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="이름:" label-for="username">
              <b-form-input
                id="username"
                v-model="formData.username"
                required
                style="height: 40px"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="비밀번호" label-for="password">
              <validation-provider
                #default="{ errors }"
                name="비밀번호"
                rules="required"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="password"
                    v-model="formData.password"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    name="password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small v-if="errors.length > 0" class="text-danger"
                  >비밀번호 필수입니다.</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="비밀번호 확인" label-for="passwordCheck">
              <validation-provider
                #default="{ errors }"
                name="비밀번호 확인"
                rules="required|confirmed:비밀번호"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="passwordCheck"
                    v-model="formData.passwordCheck"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordCheckFieldType"
                    class="form-control-merge"
                    name="passwordCheck"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordCheckToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordCheckVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small v-if="errors.length > 0" class="text-danger"
                  >비밀번호가 다릅니다.</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-form-group>
              <b-button variant="warning" class="mr-1" @click="onCancel">
                취소
              </b-button>
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click="validationForm"
              >
                저장
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
// eslint-disable-next-line no-unused-vars
import { email, required } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';

export default {
  components: {
    // validations
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      formData: {},
      statusOption: ['Y', 'N'],
      roleOption: ['ROLE_ADMIN', 'ROLE_USER'],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordCheckToggleIcon() {
      return this.passwordCheckFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon';
    },
  },
  async created() {
    this.formData = await this.$store.dispatch('auth/FETCH_AUTH_INFO', {
      userId: this.$store.state.auth.usrId,
    });
  },
  methods: {
    async validationForm() {
      this.$refs.editForm.validate().then(async (success) => {
        if (success) {
          const res = await this.$store.dispatch('auth/FETCH_AUTH_UPDATE', {
            userId: this.formData.userId,
            password: this.formData.password,
          });
          if (res.resultFlag) {
            alert('수정 완료되었습니다.');
          }
          this.$bvModal.hide('modal-lg');
        }
      });
    },
    onCancel() {
      this.$bvModal.hide('modal-lg');
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
