import Vue from "vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL || "http://220.76.251.227:9090",
  // timeout: 1000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json; charset=utf-8",
  },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
