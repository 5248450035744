<template>
  <div>
    <div class="mb-2">
      <h3>▶ 계량기정보</h3>
      <span style="color:red;"><b>※ 해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다.</b></span>
    </div>
		<div v-show="loading" class="text-center loadingArea">
			<b-spinner class="m-5" label="Busy" variant="dark" />
		</div>
    <div v-show="!loading">
      {{ meterType == '1' ? '＊일반표제부와 매칭된 계량기 현황' : '＊총괄표제부와 매칭된 계량기 현황' }}
      <b-table class="table-style" striped :items="items" :fields="fields" responsive="sm" />
			<div v-show="isData" class="noData">
        <p>데이터가 존재하지 않습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BSpinner } from 'bootstrap-vue';

export default {
  components: {
    BTable,
		BSpinner,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
    meterType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
			loading: true,
			isData: false,
      fields: [
        {
          key: 'rownum',
          label: 'No.',
        },
        {
          key: 'engy_esncno',
          label: '계량기 고객번호',
        },
        {
          key: 'engy_nm',
          label: '에너지원 구분',
        },
        {
          key: 'use_purps_cd',
          label: '용도',
        },
        {
          key: 'engy_sply_kik_cd',
          label: '공급기관',
        },
      ],
      items: [],
    };
  },
  async created() {
    // 페이지 생성 시 데이터 불러옴
    await this.$store
      .dispatch('main/FETCH_ENERGY_METER_INFO_DATA', {
        dataCode: 'energyMeter',
        meterType: this.meterType, // 1: 일반표제부 계량기, 2: 총괄표제부 계량기
        mgmBldPk: this.mgmBldPk,
      })
      .then(() => {
        this.items = this.$store.state.main.meterList;
				if (this.items.length === 0) this.isData = true;
      })
      .catch(() => {});
			this.loading = false;
  },
};
</script>

<style scoped>
.body-style {
  font-family: Helvetica Neue, Arial, sans-serif;
}

.table-style {
  position: relative;
  white-space: nowrap;
}
</style>
