<template>
  <div>
    <div style="text-align: right; margin-top: 10px; margin-right: 40px;font-size: 15px; font-weight: bold">
      ※ 2017~2019 3개년 평균 데이터 활용
    </div>
    <div v-for="page in totalPages" :key="page">
      <pdf :src="pdfUrl" :page="page" />
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';

export default {
  components: {
    pdf,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pdfUrl: `/api/main/pdf/${this.mgmBldPk}.pdf`,
      totalPages: 3, // 전체 페이지 수를 설정
    };
  },
};
</script>
