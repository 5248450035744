<template>
  <div>
    <b-row>
      <b-col md="6" sm="8" />
      <b-col md="6" class="mb-1">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="검색" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12">
        <b-table
          hover
          responsive
          :current-page="currentPage"
          :fields="header"
          :items="rows"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(createdDt)="row">
            <div class="d-flex justify-content-between">
              <div class="d-inline-block">
                <b-badge
                  v-if="row.item.engyBldYn === 'Y'"
                  size="sm"
                  pill
                  variant="warning"
                  style="margin-right: 5px"
                >
                  에너지
                </b-badge>
                <b-badge
                  v-if="row.item.bepaYn === 'Y'"
                  size="sm"
                  pill
                  variant="success"
                  style="margin-right: 5px"
                >
                  진단
                </b-badge>
                <b-badge
                  v-if="row.item.kbossYn === 'Y'"
                  size="sm"
                  pill
                  variant="danger"
                  style="margin-right: 5px"
                >
                  KBOSS
                </b-badge>
              </div>
              <div class="d-inline-block ml-auto">
                <feather-icon size="36" icon="ZoomInIcon" class="cursor-pointer" @click="goDtl(row.item)" />
              </div>
            </div>
          </template>
        </b-table>
        <div class="tbFooter">
          <div>
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="rows.length"
              aria-controls="my-table"
            />
          </div>
        </div>
        <div v-show="loading" class="text-center pt-1">
          <b-spinner class="m-5" label="Busy" variant="dark" />
        </div>
        <div>
          <b-modal
            v-model="modalBuildTotal"
            centered
            ok-only
            ok-title="닫기"
            size="xl"
            :title="`건물 에너지 광역검진정보 (소재지: ${buildTotalInfo.bldAddr} / ${buildTotalInfo.bldNm}) `"
          >
            <build-total-main :mgm-bld-pk="buildTotalInfo.mgmBldPk" />
          </b-modal>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BBadge,
} from 'bootstrap-vue';
import BuildTotalMain from '~/building/buildTotalInfo/buildTotalMain.vue';

export default {
  components: {
    BuildTotalMain,
    BModal,
    BSpinner,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BBadge,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      selected: [],
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      header: [
        { label: '건축물명', key: 'bldNm' },
        { label: '건물물 명칭', key: 'dongNm' },
        { label: '소재지', key: 'platAddr' },
        { label: '건물정보 ', key: 'createdDt' },
      ],
      rows: [],
      // 선택한 건물 PK
      selectMgmBlkPk: '',
      modalBuildTotal: false, // 건물 정보 토탈
      buildTotalInfo: {},
    };
  },
  async mounted() {
    this.rows = await this.$store.dispatch('auth/FETCH_AUTH_BOOKMARK', {
      userId: this.userId,
    });
    this.loading = false;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async goDtl(bldInfoData) {
      this.$store.state.main.selectMenuComp = null;

      // modal 타이틀에 건물정보, 주소를 입력하기 위해 db 조회
      const res = await this.$store.dispatch('main/FETCH_BUILD_TOTAL_TITLE_DATA', {
        mgmBldPk: bldInfoData.mgmBldPk,
      });

      this.modalBuildTotal = !this.modalBuildTotal;
      this.buildTotalInfo.bldNm = res.bldnm;
      this.buildTotalInfo.bldAddr = res.roadplataddr;
      this.buildTotalInfo.mgmBldPk = res.mgmbldpk;

      // 해당 건물로 이동
      gis.search.moveToBldCrd(map, {
        x: bldInfoData.stX,
        y: bldInfoData.stY,
        mgmBldPk: bldInfoData.mgmBldPk,
      });
    },
  },
};
</script>
<style scoped>
.tbFooter {
  text-align: center;
  padding: 30px 0;
}

.tbFooter div {
  display: inline-block;
}

.regBtnArea {
  float: right;
}
</style>
