import Vue from "vue";
import { ModalPlugin, ToastPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import moment from "moment";
import VueMomentJS from "vue-momentjs";
// 외부 라이브러리 로드
import LoadScript from "vue-plugin-load-script";
import VueCookies from "vue-cookies";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

// alert창
import "@/libs/sweet-alerts";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// 외부 라이브러리
Vue.use(LoadScript);
Vue.use(VueCookies);

// 시간 라이브러리
Vue.use(VueMomentJS, moment);

// 콤마찍기 - 전역변수
Vue.filter("makeComma", (val) => {
  const parts = String(val).split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? `.${parts[1]}` : "");
});

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = true;

const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

window.app = vm;
