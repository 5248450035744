<template>
  <div>
    <div class="mb-2">
      <h3>▶ 에너지소비량</h3>
      <span style="color:red;"><b>※ 해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다.</b></span>
    </div>
    <div>
      <b-row>
        <b-col cols="8">
          <h5>• 소비량 분리 (단위환산, 1MJ=0.278kWh, 1Mcal = 1.163kWh)</h5>
        </b-col>
        <b-col cols="4">
          <b-form-group label-for="useYyCd">
            <v-select
              id="useYyCd"
              v-model="useYySelected"
              label="cdNm"
              taggable
              push-tags
              :options="commCodeData.USE_YY_CD"
              @input="onClickEnergyUseInfo($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table-simple small responsive class-name="row-style">
            <colgroup>
              <col />
              <col />
            </colgroup>
            <b-thead head-variant="light">
              <b-tr>
                <b-th />
                <b-th class="transform-style">
                  전기(kWh/m²)
                </b-th>
                <b-th class="transform-style">
                  가스(kWh/m²)
                </b-th>
                <b-th class="transform-style">
                  열(kWh/m²)
                </b-th>
                <b-th class="transform-style">
                  합계(kWh/m²)
                </b-th>
                <b-th class="transform-style">
                  백분위(%)
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>난방</b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{ getEnergyUseData[0].elechetgqty | makeComma }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{ getEnergyUseData[0].gashetgqty | makeComma }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{ getEnergyUseData[0].hetghetgqty | makeComma }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{
                    (Math.round(
                      (getEnergyUseData[0].elechetgqty +
                        getEnergyUseData[0].gashetgqty +
                        getEnergyUseData[0].hetghetgqty) *
                        100,
                    ) /
                      100)
                      | makeComma
                  }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  <div>
                    <div class="progress-container">
                      <div
                        class="progress-bar"
                        :style="{ width: getEnergyUseData[0].allhetgqtyperc + '%' }"
                      ></div>
                    </div>
                    <div class="progress-text">{{ getEnergyUseData[0].allhetgqtyperc }}%</div>
                  </div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>냉방</b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{ getEnergyUseData[0].elecaconqty | makeComma }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{ getEnergyUseData[0].gasaconqty | makeComma }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{ getEnergyUseData[0].hetgaconqty | makeComma }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{
                    (Math.round(
                      (getEnergyUseData[0].elecaconqty +
                        getEnergyUseData[0].gasaconqty +
                        getEnergyUseData[0].hetgaconqty) *
                        100,
                    ) /
                      100)
                      | makeComma
                  }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  <div>
                    <div class="progress-container">
                      <div
                        class="progress-bar"
                        :style="{ width: getEnergyUseData[0].allaconqtyperc + '%' }"
                      ></div>
                    </div>
                    <div class="progress-text">{{ getEnergyUseData[0].allaconqtyperc }}%</div>
                  </div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>기저</b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{ getEnergyUseData[0].elecbaseqty | makeComma }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{ getEnergyUseData[0].gasbaseqty | makeComma }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{ getEnergyUseData[0].hetgbaseqty | makeComma }}
                </b-td>

                <b-td v-if="getEnergyUseData[0]">
                  {{
                    (Math.round(
                      (getEnergyUseData[0].elecbaseqty +
                        getEnergyUseData[0].gasbaseqty +
                        getEnergyUseData[0].hetgbaseqty) *
                        100,
                    ) /
                      100)
                      | makeComma
                  }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  <div>
                    <div class="progress-container">
                      <div
                        class="progress-bar"
                        :style="{ width: getEnergyUseData[0].allbaseqtyperc + '%' }"
                      ></div>
                    </div>
                    <div class="progress-text">{{ getEnergyUseData[0].allbaseqtyperc }}%</div>
                  </div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>합계</b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{
                    (Math.round(
                      (getEnergyUseData[0].elechetgqty +
                        getEnergyUseData[0].elecaconqty +
                        getEnergyUseData[0].elecbaseqty) *
                        100,
                    ) /
                      100)
                      | makeComma
                  }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{
                    (Math.round(
                      (getEnergyUseData[0].gashetgqty +
                        getEnergyUseData[0].gasaconqty +
                        getEnergyUseData[0].gasbaseqty) *
                        100,
                    ) /
                      100)
                      | makeComma
                  }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{
                    (Math.round(
                      (getEnergyUseData[0].hetghetgqty +
                        getEnergyUseData[0].hetgaconqty +
                        getEnergyUseData[0].hetgbaseqty) *
                        100,
                    ) /
                      100)
                      | makeComma
                  }}
                </b-td>

                <b-td v-if="getEnergyUseData[0]">
                  {{
                    (Math.round(
                      (getEnergyUseData[0].elechetgqty +
                        getEnergyUseData[0].elecaconqty +
                        getEnergyUseData[0].elecbaseqty +
                        (getEnergyUseData[0].gashetgqty +
                          getEnergyUseData[0].gasaconqty +
                          getEnergyUseData[0].gasbaseqty) +
                        (getEnergyUseData[0].hetghetgqty +
                          getEnergyUseData[0].hetgaconqty +
                          getEnergyUseData[0].hetgbaseqty)) *
                        100,
                    ) /
                      100)
                      | makeComma
                  }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  <div>
                    <div class="progress-container">
                      <div
                        class="progress-bar"
                        :style="{ width: getEnergyUseData[0].allallqtyperc + '%' }"
                      ></div>
                    </div>
                    <div class="progress-text">{{ getEnergyUseData[0].allallqtyperc }}%</div>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row v-if="this.totareaDstrb === 'Y'">
        <b-col cols="12">
          <h6 class="totareaStyle">
            * 총괄표제부의 에너지 소비량을 건물의 연면적으로 배분한 값입니다.
          </h6>
        </b-col>
      </b-row>
      <b-row v-if="isSudoQty">
        <b-col cols="8">
          <h5>• 수도사용량</h5>
        </b-col>
        <b-col cols="4" />
        <b-col cols="12">
          <b-table-simple small responsive class-name="row-style">
            <colgroup>
              <col />
              <col />
            </colgroup>
            <b-thead head-variant="light">
              <b-tr>
                <b-th />
                <b-th class="transform-style">
                  사용량(Ton)
                </b-th>
                <b-th class="transform-style">
                  원단위(L/m²)
                </b-th>
                <b-th class="transform-style">
                  원단위(L/인)
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>합계</b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{
                    getEnergyUseData[0].sudoqty
                      ? Math.round(getEnergyUseData[0].sudoqty * 100) / 100
                      : '-' | makeComma
                  }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{
                    getEnergyUseData[0].totarea > 0
                      ? Math.round((getEnergyUseData[0].sudoqty * 1000) / getEnergyUseData[0].totarea)
                      : '-' | makeComma
                  }}
                </b-td>
                <b-td v-if="getEnergyUseData[0]">
                  {{
                    getEnergyUseData[0].c > 0
                      ? Math.round((getEnergyUseData[0].memcnt * 1000) / getEnergyUseData[0].totarea)
                      : '-' | makeComma
                  }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <echart-bar
            :mgm-bld-pk="mgmBldPk"
            :energy-chart-type="engyKind"
            :use-yy-selected="this.useYySelected.cdId"
          />
        </b-col>
        <b-col cols="6">
          <echart-stacked-area
            :mgm-bld-pk="mgmBldPk"
            :energy-chart-type="clsfKind"
            :use-yy-selected="this.useYySelected.cdId"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BRow,
  BCol,
  BFormGroup,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';

import EchartStackedArea from '@/views/building/EchartEnergy/EchartStackedArea';
import vSelect from 'vue-select';
import EchartBar from '@/views/building/EchartEnergy/EchartBar';

export default {
  components: {
    EchartBar,

    EchartStackedArea,

    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BProgress,
    BProgressBar,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // 넘길 변수 설정
      clsfKind: 'clsfKind',
      engyKind: 'engyKind',

      useYySelected: { cdNm: '2017년', cdId: '2017' },
      totareaDstrb: 'N', // Y일경우총 괄표제부의 에너지 소비량을 건물의 연면적으로 배분한 값
      isSudoQty: false,

      // 해당 건물의 에너지 사용량 데이터가 있는 년도
      // buildUseYyCd: [],
    };
  },
  computed: {
    ...mapGetters({
      getEnergyUseData: 'main/getEnergyUseData',
      commCodeData: 'main/getCommCodeData',
      mainSelected: 'main/getSelectedCodeData',
      buildUseYyCd: 'main/getBuildUseYyData',
    }),
  },
  async mounted() {
    // 페이지 생성 시 데이터 불러옴
    this.useYySelected = await this.mainSelected.useYyCd;
    this.onClickEnergyUseInfo();
    this.loadTotareaDstrb();

    // 에너지 사용량만 있는 년도 불러옴
    await this.$store.dispatch('main/FETCH_BUILD_USE_YY_CD', { mgmBldPk: this.mgmBldPk });
  },
  methods: {
    async onClickEnergyUseInfo() {
      await this.$store.dispatch('main/FETCH_ENERGY_USE_DATA', {
        mgmBldPk: this.mgmBldPk,
        useYyCd: this.useYySelected.cdId,
      });
      if (this.getEnergyUseData[0].sudoqty !== 0) this.isSudoQty = true;
    },
    loadTotareaDstrb() {
      // * 총괄표제부의 에너지 소비량을 건물의 연면적으로 배분한 값입니다.
      this.$store
        .dispatch('main/FETCH_TOTAREA_DSTRB_DATA', {
          dataCode: 'totareaDstrb',
          mgmBldPk: this.mgmBldPk,
        })
        .then(() => {
          this.totareaDstrb = this.$store.state.main.totareaDstrb[0].fn_get_eng_div_yn;
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
th,
td {
  border: 1px solid #cccccc;
  margin: auto;
  text-align: center;
}

.transform-style {
  text-transform: none;
}

.totareaStyle {
  font-size: 11px;
  text-align: right;
  color: blue;
}

/* 스타일을 적용할 프로그레스 바 컨테이너 */
.progress-container {
  display: inline-block;
  width: 50%;
  background-color: #ccc;
  overflow: hidden;
}

/* 프로그레스 바 */
.progress-bar {
  width: 46px;
  height: 13px;
  background-color: #4441d4;
}
.progress-text {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  width: 29px;
  height: 11px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4441d4;
}
</style>
