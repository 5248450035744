export default {
  adminList: [],
  adminHeader: [
    { label: '선택', key: 'selected' },
    { label: '아이디', key: 'userId' },
    { label: '이름', key: 'username' },
    { label: '상태', key: 'status' },
    { label: '권한', key: 'roleId' },
    { label: '권한시작', key: 'roleStart' },
    { label: '권한종료', key: 'roleEnd' },
    { label: '가입일', key: 'createdDt' },
  ],
  adminJoin: {},
  adminUpdate: {},
  adminDelete: {},
  adminListHist: [],
  adminHeaderHist: [
    { label: '로그인 날짜', key: 'createdDt' },
    { label: '아이피', key: 'loginIp' },
  ],
  adminBuildHeader: [
    { label: '건물 PK', key: 'mgmBldPk' },
    { label: '건물 명', key: 'bldNm' },
    { label: '시군구', key: 'sigunguId' },
    { label: '동', key: 'dongId' },
    { label: '조회일', key: 'createdDt' },
  ],
  adminBuildHist: [],
  adminBookmarkHeader: [
    { label: '아이디', key: 'userId' },
    { label: '건물 명', key: 'bldNm' },
    { label: '건물 PK', key: 'mgmBldPk' },
    { label: '등록일', key: 'createdDt' },
  ],
  adminBookmarkHist: [],
};
