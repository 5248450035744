import getters from '@/store/admin/getters';
import actions from '@/store/admin/actions';
import mutations from '@/store/admin/mutations';
import state from '@/store/admin/state';

export default {
  namespaced: true, // 모듈일 경우에는 namespaced를 true로 반드시 설정해야 한다.
  state,
  getters,
  mutations,
  actions,
};
