<template>
  <div>
    <div>
      <div>
        <h4><b>건물기본정보</b></h4>
      </div>
      <div>
        <b-list-group flush>
          <b-list-group-item class="pointer" @click="goMenuComp('BuildBasicInfo')">
            <p class="purpleArea">
              ■
            </p>
            <p
              :class="selectedItem === 'BuildBasicInfo' ? 'active-item' : 'non-active-item'"
              style="text-decoration:underline;"
            >
              건축물대장
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('BuildFloorOverview')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'BuildFloorOverview' ? 'active-item' : 'non-active-item'">
              층별개요
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('BuildLicenseList')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'BuildLicenseList' ? 'active-item' : 'non-active-item'">
              인허가 리스트
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div class="mt-1">
      <div>
        <h4><b>건물에너지정보</b></h4>
      </div>
      <div>
        <b-list-group flush>
          <b-list-group-item class="pointer" @click="goMenuComp('EnergyUseSubInfo')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'EnergyUseSubInfo' ? 'active-item' : 'non-active-item'">
              에너지소비량
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('SimilarBuildingCompare')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'SimilarBuildingCompare' ? 'active-item' : 'non-active-item'">
              유사건물비교
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('EnergyMeterInfo')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'EnergyMeterInfo' ? 'active-item' : 'non-active-item'">
              계량기정보
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('NewEnergy')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'NewEnergy' ? 'active-item' : 'non-active-item'">
              신재생 포텐셜
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div v-if="bepaExist" class="mt-1">
      <div>
        <h4><b>건물진단정보</b></h4>
      </div>
      <div>
        <b-list-group flush>
          <b-list-group-item style="cursor: pointer" @click="goMenuComp('pdfBepa')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'pdfBepa' ? 'active-item' : 'non-active-item'">
              BEPA 리포트
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <div class="mt-1">
      <button
        type="button"
        class="myBuildBtn"
        :class="{ violet: KBossYn, white: !KBossYn }"
        @click="toggleKBossYn(KBossModal)"
      >
        {{ KBossYn ? ' KBOSS 도면 확인' : ' KBOSS 도면 등록' }}
      </button>
    </div>
    <div class="mt-1">
      <button
        type="button"
        class="myBuildBtn"
        :class="{ purple: bookMark, white: !bookMark }"
        @click="toggleBookMark(bookMark)"
      >
        <img v-if="bookMark" src="/star_white.png" alt="white star" style="vertical-align:bottom;" />
        <img v-if="!bookMark" src="/star_purple.png" alt="purple star" style="vertical-align:bottom;" />
        {{ bookMark ? ' 내 건물 해지 ' : ' 내 건물 지정' }}
      </button>
    </div>
    <div class="mt-1 center">
      <button type="button" class="updateSuggest" @click="goMenuComp('buildInfoEditOffer')">
        수정 제안
      </button>
    </div>

    <div class="mt-1 center">
      <button type="button" class="updateSuggest" @click="goMenuComp('BuildGradeList')">
        ※ 건물 맵핑 등급표
      </button>
    </div>

    <b-modal v-model="KBossModal" title="KBOSS 도면 등록" size="lg" centered hide-footer>
      <div>
        <div class="mb-1">
          <h3>건물의 도면 관리</h3>
        </div>
        <div v-show="KBossModalStatus === 'List'">
          <div>
            <b-table :items="items" :fields="fields" responsive="sm">
              <template #cell(selected)="{ rowSelected, item }">
                <template v-if="rowSelected">
                  <label for="chk">
                    <input
                      id="chk"
                      :value="item.kbossfloorplansn"
                      checked
                      type="checkbox"
                      @change="checkList(item.kbossfloorplansn)"
                    />
                  </label>
                </template>
                <template v-else>
                  <input
                    :id="item.kbossfloorplansn"
                    name="delCheck"
                    type="checkbox"
                    @change="checkList(item.kbossfloorplansn)"
                  />
                  <label :for="item.kbossfloorplansn" />
                </template>
              </template>
            </b-table>
          </div>
          <div class="text-right">
            <b-button variant="danger" class="mr-1" @click="kbossDataDelete">
              삭제
            </b-button>
            <b-button variant="primary" @click="kbossModalStatusChange('Create')">
              등록
            </b-button>
          </div>
        </div>
        <div v-show="KBossModalStatus === 'Create'">
          <b-row>
            <b-col md="12">
              <b-form-group label="도면 부가 설명" label-for="fileDesc">
                <b-form-input id="fileDesc" v-model="fileDesc" placeholder="도면 부가 설명을 작성해주세요" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <label for="mapFile">geojson 파일 첨부</label>
              <b-form-file
                id="mapFile"
                v-model="mapFile"
                accept=".geojson"
                placeholder="파일을 선택해주세요"
                drop-placeholder="파일을 드래그 하십시오"
              />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col class="text-center">
              <b-form-group>
                <b-button variant="warning" class="mr-1" @click="kbossModalStatusChange('List')">
                  취소
                </b-button>
                <b-button variant="primary" class="mr-1" @click="selectFile">
                  저장
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BButton,
  BModal,
  BTable,
  BCol,
  BForm,
  BRow,
  BFormInput,
  BFormFile,
  BFormGroup,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import axios from 'axios';
import { mapGetters } from 'vuex';
import EventBus from '@/utils/eventBus';
import AuthProfile from '~/auth/AuthProfile.vue';

export default {
  components: {
    BFormGroup,
    BForm,
    vSelect,
    BCol,
    AuthProfile,
    BModal,
    BListGroup,
    BListGroupItem,
    BButton,
    BTable,
    BRow,
    BFormInput,
    BFormFile,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bookMark: false,
      bepaExist: false,
      KBossYn: false, // KBOSS 도면 저장 여부
      KBossModal: false, // Modal 처리
      selectedItem: 'BuildBasicInfo',
      selected: [],
      mapFile: null,
      fileDesc: '', // 도면 부가 설명

      fields: [
        { key: 'selected', label: '선택' },
        { key: 'rownum', label: 'no' },
        { key: 'filename', label: '도면 파일명' },
        { key: 'filedesc', label: '도면 부가설명' },
        { key: 'createdid', label: '등록자' },
        { key: 'createddt', label: '등록일시' },
      ],
      items: [],
      beforeMenu: '',
    };
  },

  computed: {
    ...mapGetters({
      KBossModalStatus: 'main/getKBossModalStatus',
    }),
  },
  created() {
    EventBus.$on('use-eventbus', () => {
      this.goMenuComp(this.beforeMenu);
    });
  },
  async mounted() {
    // 선택한 건물 내 건물로 등록한 내역 조회
    this.bookMark = await this.$store.dispatch('main/FETCH_BUILD_GET_BOOKMARK_DATA', {
      mgmBldPk: this.mgmBldPk,
    });

    // BEPA 대상 건물 조회
    this.bepaExist = await this.$store.dispatch('main/FETCH_PDF_BEPA_EXIST', {
      mgmBldPk: this.mgmBldPk,
    });

    // 해당 건물에 KBOSS 도면이 저장되어 있는지 확인
    this.KBossYn = await this.$store.dispatch('main/FETCH_BUILD_GET_KBOSS_YN', {
      mgmBldPk: this.mgmBldPk,
    });
  },
  methods: {
    // 메뉴 이동
    async goMenuComp(compName) {
      if (compName === 'SimilarBuildingCompare') {
        this.beforeMenu = this.selectedItem;
      }
      this.selectedItem = compName;
      await this.$store.dispatch('main/FETCH_SELECT_MENU_COMP', compName);
    },
    // 내 건물 등록
    async toggleBookMark(bookMark) {
      await this.$store.dispatch('main/FETCH_BUILD_SET_BOOKMARK_DATA', {
        mgmBldPk: this.mgmBldPk,
        bookmarkYn: (this.bookMark = !bookMark),
      });
      this.bookMark = !bookMark;
    },
    async toggleKBossYn(KBossModal) {
      this.KBossModal = !KBossModal;
      // 버튼 누르면 초기화
      this.$store.commit('main/SET_KBOSS_MODAL_STATUS', 'List');
      // KBOSS 등록 리스트
      this.items = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_MAP_DATA', {
        mgmBldPk: this.mgmBldPk,
      });
    },
    async goDtl(mgmBldPk) {
      console.log(mgmBldPk);
    },
    async kbossModalStatusChange(status) {
      // 등록은 건물당 1개의 파일만 등록 가능
      if (this.items.length >= 1) {
        alert('1개의 파일만 등록할 수 있습니다.');
      } else {
        this.mapFile = null;
        this.fileDesc = '';

        await this.$store.commit('main/SET_KBOSS_MODAL_STATUS', status);
      }
    },
    checkList(rowNum) {
      const idx = this.selected.indexOf(rowNum);
      if (idx >= 0) {
        this.selected.splice(idx, 1);
      } else {
        this.selected.push(rowNum);
      }
    },
    async kbossDataDelete() {
      if (this.selected.length === 0) {
        alert('삭제할 도면을 선택해주세요.');
      } else {
        const res = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_DELETE', {
          mgmBldPk: this.mgmBldPk,
          userId: this.$store.state.auth.usrId,
          selected: this.selected,
        });

        if (res) {
          alert('삭제완료 되었습니다.');

          this.items = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_MAP_DATA', {
            mgmBldPk: this.mgmBldPk,
          });

          const checkboxes = document.getElementsByName('delCheck');

          checkboxes.forEach((checkbox) => {
            // eslint-disable-next-line no-param-reassign
            checkbox.checked = false;
          });

          this.KBossYn = false;
        } else {
          alert('삭제 권한이 없습니다.');
        }
      }
    },
    selectFile(event) {
      const formData = new FormData();

      formData.append('userId', this.$store.state.auth.usrId);
      formData.append('mgmBldPk', this.mgmBldPk);
      formData.append('files', this.mapFile);
      formData.append('fileDesc', this.fileDesc);

      axios
        .post(`/api/main/files`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(async () => {
          alert('등록하였습니다.');

          // 버튼 누르면 초기화
          this.$store.commit('main/SET_KBOSS_MODAL_STATUS', 'List');

          this.items = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_MAP_DATA', {
            mgmBldPk: this.mgmBldPk,
          });

          this.KBossYn = true;
        })
        .catch((error) => {
          alert(error.message);
        });
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer !important;
}
.list-group-item {
  border-width: 0 0 0 0 !important;
}
.purpleArea {
  color: #4441d4;
  display: contents;
}
.updateSuggest {
  text-decoration: underline;
  color: #82868b;
  border: none;
  background: none;
}
.center {
  text-align: center;
}
.non-active-item {
  display: contents;
}
.active-item {
  display: inline;
  color: #4441d4;
  font-weight: bold;
  text-decoration: underline;
}
.myBuildBtn {
  width: 100%;
  padding: 10px;
  border: 1px solid #4441d4;
  border-radius: 10px;
  font-weight: bold;
}
.purple {
  background: #4441d4;
  color: white;
}
.white {
  background: white;
  color: #4441d4;
}
.violet {
  background: #862eb2;
  border: 1px solid #862eb2;
  color: white;
}
</style>
