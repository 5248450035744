import axios from '@axios';

export default {
  state: {
    noticeList: [], // 공지사항 리스트
    noticeView: {}
  },
  getters: {},
  mutations: {
    setNoticeList(state, payload) {
      state.noticeList = payload;
    },
    setNoticeView(state, payload) {
      state.noticeView = payload;
    },
    setOnSubmitNotice(state, payload) {
      state.noticeView = payload;
    },
  },
  actions: {
    async loadNoticeList({ commit }) {
      await axios.get('/api/notice/selectNoticeList', {
      }).then(res => {
        // console.log(res.data);
        commit('setNoticeList', res.data.resultData);
      })
        .catch(err => {
          console.log('실패')
          console.error(err)
        })
    },
    async loadNoticeView({ commit }, paylaod) {
      await axios.post(`/api/notice/selectNoticeView?bbsId=${paylaod.bbsId}`, {
      }).then(res => {
        commit('setNoticeView', res.data.resultData);
      })
        .catch(err => {
          console.log('실패')
          console.error(err)
        })
    },
    async onSubmitNotice({ commit }, paylaod) {
      await axios.post('/api/notice/onSubmitNotice', {
        bbsId: paylaod.bbsId,
        bbsTitle: paylaod.bbsTitle,
        useYn: paylaod.useYn,
        fixYn: paylaod.fixYn,
        bbsContent: paylaod.bbsContent,
      }).then(res => {
        commit('setOnSubmitNotice', res.data.resultData);
      })
        .catch(err => {
          console.log('실패')
          console.error(err)
        })
    },
    async deleteNotice({ commit }, paylaod) {
      await axios.post(`/api/notice/deleteNotice`, {
        bbsId: paylaod.bbsId,
      }).then(res => {
        commit('setDeleteNotice', res.data.resultData);
      })
        .catch(err => {
          console.log('실패')
          console.error(err)
        })
    },
  },
}
