const utils = {
  // obj가 map형식으로 넘어온 것의 기본값 찾기
  fnFindDefaultValue4Map(obj, findKey) {
    const keys = Object.keys(obj); // map의 key들을 찾음.
    const result = {};

    // 각 객체들의 key만큼 for문 돌려
    keys.forEach((element) => {
      // 객체에서 key를 찾아서 for문 돌려(value들은 array로 가져오니까!!!)
      // array에 값이 있으면 돌려!
      const key = element;
      const defaultArr = [];

      if (obj[key].length !== 0) {
        obj[element].forEach((ele) => {
          if (ele[findKey] === "Y") {
            defaultArr.push(ele);
          }
        });
      }

      if (defaultArr.length > 0) {
        result[key] = defaultArr;
      }
    });
    return result;
  },

  // obj가 array형식으로 넘어온 것의 기본값 찾기
  fnFindDefaultValue4Array(obj, findKey) {
    const result = [];

    // 배열 형태면 foreach 돌려서 꺼내옴
    obj.forEach((element) => {
      if (element[findKey] === "Y") {
        result.push(element);
        return false; // break
      }
    });
    return result;
  },

  // 엑셀 다운로드
  fnExcelDown(res) {
    const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });

    // 엑셀 태그 설정(다운로드 후 삭제됨)
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", decodeURI(res.headers.filename));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  // sweet alert!!(이쁜 alert창을 띄워줌!)
  // type에 'success'가 들어오면 성공 alert창이 나옴. 그 외에는 경고 alert창이 나옴.
  fnAlert(obj, message, type) {
    if (type !== undefined) {
      obj.$swal({
        text: message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-dark',
        },
        buttonsStyling: false,
      })
    } else {
      // type이 success가 아닌 경우
      obj.$swal({
        text: message,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-dark',
        },
        buttonsStyling: false,
      })
    }
  },

  // sweet confirm!!(이쁜 confirm창을 띄워줌!!)
  fnConfirm(obj, title, html) {
    return obj.$swal({
      title,
      html,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-dark',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    })
  }
};

// eslint-disable-next-line import/prefer-default-export
export { utils };
