import { authAxiosCall } from '@api/auth';
import { cookie } from '@/utils/cookie';

export default {
  async FETCH_AUTH_JOIN({ commit }, payload) {
    const { data } = await authAxiosCall.fetchAuthJoin(payload);
    if (data.resultFlag) {
      commit('SET_AUTH_JOIN', data.resultData);
    }
    return data;
  },
  async FETCH_AUTH_LOGIN({ commit }, payload) {
    const { data } = await authAxiosCall.fetchAuthLogin(payload);
    if (data.resultFlag) {
      commit('SET_IS_AUTHENTICATED', true);
      commit('SET_ROLE_ID', data.resultData.roleId);
      commit('SET_USR_ID', data.resultData.usrId);

      cookie.saveAccessToken(data.resultData.accessToken);
      cookie.saveRefreshToken(data.resultData.refreshToken);
    }

    return data;
  },

  FETCH_AUTH_LOGOUT({ commit }) {
    commit('SET_CLEAR_DATA');
  },

  async FETCH_AUTH_INFO({ commit }, payload) {
    const { data } = await authAxiosCall.fetchAuthInfo(payload);
    if (data.resultFlag) {
      commit('SET_AUTH_INFO', data.resultData);
    }
    return data.resultData;
  },

  // eslint-disable-next-line no-unused-vars
  async FETCH_AUTH_UPDATE({ commit }, payload) {
    const { data } = await authAxiosCall.fetchAuthUpdate(payload);
    return data;
  },
  async FETCH_AUTH_BOOKMARK({ commit }, payload) {
    const { data } = await authAxiosCall.fetchAuthBookmark(payload);
    return data.resultData;
  },
};
