// HTTP reqeust & response와 관련된 기본 설정을 해줌
// eslint-disable-next-line import/no-cycle
import { instance, instanceWithAuth } from '@/api';

const config = {
  url_commCode: '/api/commCode/',
  url_auth: '/api/auth/',
};

const authAxiosCall = {
  // 회원가입
  fetchAuthJoin(payload) {
    return instance.post(`${config.url_auth}getAuthJoin`, payload);
  },
  fetchAuthLogin(payload) {
    return instance.post(`${config.url_auth}getAuthLogin`, payload);
  },
  fetchAuthInfo(payload) {
    return instanceWithAuth.post(`${config.url_auth}getAuthInfo`, payload);
  },
  fetchAuthUpdate(payload) {
    return instanceWithAuth.post(`${config.url_auth}getAuthUpdate`, payload);
  },
  fetchAuthBookmark(payload) {
    return instanceWithAuth.post(`${config.url_auth}getAuthBookmark`, payload);
  },
};

// eslint-disable-next-line import/prefer-default-export
export { authAxiosCall };
