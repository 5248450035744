export default {
  SET_ADMIN_LIST(state, payload) {
    state.adminList = payload.map((v) => ({
      rowNum: v.rowNum,
      userId: v.userId,
      username: v.username,
      roleId: v.roleId,
      status: v.status,
      roleStart: v.roleStart,
      roleEnd: v.roleEnd,
      createdDt: v.createdDt,
    }));
  },
  SET_ADMIN_INFO(state, payload) {
    state.adminInfo = payload;
  },
  SET_ADMIN_JOIN(state, payload) {
    state.adminJoin = payload;
  },
  SET_ADMIN_UPDATE(state, payload) {
    state.adminUpdate = payload;
  },
  SET_ADMIN_DELETE(state, payload) {
    state.adminDelete = payload;
  },
  SET_ADMIN_LOGIN_HIST(state, payload) {
    state.adminListHist = payload.map((v) => ({
      createdDt: v.createdDt,
      loginIp: v.loginIp,
    }));
  },
  SET_ADMIN_BUILD_HIST(state, payload) {
    state.adminBuildHist = payload.map((v) => ({
      mgmBldPk: v.mgmBldPk,
      bldNm: v.bldNm,
      sigunguId: v.sigunguId,
      dongId: v.dongId,
      createdDt: v.createdDt,
    }));
  },
  SET_ADMIN_BOOKMARK_HIST(state, payload) {
    state.adminBookmarkHist = payload.map((v) => ({
      userId: v.userId,
      bldNm: v.bldNm,
      mgmBldPk: v.mgmBldPk,
      createdDt: v.createdDt,
    }));
  },
};
