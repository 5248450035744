import getters from "@/store/main/getters";
import actions from '@/store/main/actions';
import mutations from '@/store/main/mutations';
import state from '@/store/main/state';

export default {
  namespaced: true, // 모듈일 경우에는 namespaced를 true로 반드시 설정해야 한다.
  state,
  getters,
  mutations,
  actions,
};
