<template>
  <div>
    <div class="mb-2">
      <h3>▶ 신재생 포텐셜</h3>
      <span style="color:red;"><b>※ 해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다.</b></span>
    </div>
    <div>
			<div>
      	<h5 class="heatEnergyTitle">
• 지열 에너지 잠재량
</h5>
				<span class="menuHelp" @mouseout="mout" @mouseover="mover">
					<img src="@/assets/images/logo/help.png" />
				</span>
				<span v-show="helpBoxShow" class="menuHelpBox heatEnergyExp">
					<h5 style="font-weight:600;">지열에너지 포텐셜 계산</h5>
					(0) 건폐율 80% 이하 건물 선정<br>
					(1) 건축 면적 [㎡] X 0.107 [kW/㎡] X 0.8 = 000kW (히트펌프 용량)<br>
					(2) (대지 면적 - 건축 면적) [㎡] X 0.8 = 000 ㎡ (수직 지중열교환기 설치 가용 면적)<br>
					(3-1) 가용 면적(2항)÷36 [㎡/홀] = 000홀 (CASE 1의 수직 지중열교환기 개수)<br>
					(3-2) 가용 면적(2항)÷25 [㎡/홀] = 000홀 (CASE 2의 수직 지중열교환기 개수)<br>
					(3-3) 가용 면적(3항)÷16 [㎡/홀] = 000홀 (CASE 3의 수직 지중열교환기 개수)<br>
					(4-1) 수직 지중열교환기 개수(3-1항)X10 [kW/홀] = 000kW (CASE 1 용량)<br>
					(4-2) 수직 지중열교환기 개수(3-2항)X10 [kW/홀] = 000kW (CASE 2 용량)<br>
					(4-3) 수직 지중열교환기 개수(3-3항)X10 [kW/홀] = 000kW (CASE 3 용량)<br>
				</span>
			</div>
      <div class="heatContentWrap">
        <div class="heatContent">
- 히트펌프 용량 : {{ heatPumpCap | toFixed(1) }}kW
</div>
        <div class="heatContent">
- 수직 지중열교환기 설치 가용 면적 : {{ uafioVghe | toFixed(1) }}㎡
</div>
        <div class="heatContent">
          - 수직 지중열교환기 개수 : {{ vgheQty1 | toFixed(1) }}개
          <p class="vgheClass">
            (CASE 1 기준)
          </p>
        </div>
        <div class="heatContent">
          - 수직 지중열교환기 용량 : {{ vgheCap1 | toFixed(1) }}kW
          <p class="vgheClass">
            (CASE 1 기준)
          </p>
        </div>
      </div>
      <div>
        <echart-bar-heat
          :mgm-bld-pk="mgmBldPk"
          :series-data1="this.seriesData1"
          :series-data2="this.seriesData2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EchartBarHeat from '@/views/building/EchartEnergy/EchartBarHeat';

export default {
  components: {
    EchartBarHeat,
  },
  filters: {
    toFixed(val, num) {
      const out = Number(parseFloat(val).toFixed(num));

      if (isNaN(out)) {
        return '-';
      }

      return out;
    },
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newEnergyHeatData: {},
      heatPumpCap: '',
      uafioVghe: '',
      vgheQty1: '',
      vgheQty2: '',
      vgheQty3: '',
      vgheCap1: '',
      vgheCap2: '',
      vgheCap3: '',
      calcMessage: '',
      seriesData1: [],
      seriesData2: [],
			helpBoxShow: false,
    };
  },
  created() {
    // 페이지 생성 시 데이터 불러옴
    this.loadNewEnergyHeat();
  },
  methods: {
    async loadNewEnergyHeat() {
      await this.$store
        .dispatch('main/FETCH_NEW_ENERGY_HEAT_DATA', { mgmBldPk: this.mgmBldPk })
        .then(() => {
          this.newEnergyHeatData = this.$store.state.main.newEnergyHeat;
          this.heatPumpCap = this.newEnergyHeatData.heatPumpCap;
          this.uafioVghe = this.newEnergyHeatData.uafioVghe;
          this.vgheQty1 = this.newEnergyHeatData.vgheQty1;
          this.vgheQty2 = this.newEnergyHeatData.vgheQty2;
          this.vgheQty3 = this.newEnergyHeatData.vgheQty3;
          this.vgheCap1 = this.newEnergyHeatData.vgheCap1;
          this.vgheCap2 = this.newEnergyHeatData.vgheCap2;
          this.vgheCap3 = this.newEnergyHeatData.vgheCap3;
          this.calcMessage = this.newEnergyHeatData.calcMessage;

          this.seriesData1.push(this.newEnergyHeatData.vgheQty1.toFixed(2));
          this.seriesData1.push(this.newEnergyHeatData.vgheQty2.toFixed(2));
          this.seriesData1.push(this.newEnergyHeatData.vgheQty3.toFixed(2));

          this.seriesData2.push(this.newEnergyHeatData.vgheCap1.toFixed(2));
          this.seriesData2.push(this.newEnergyHeatData.vgheCap2.toFixed(2));
          this.seriesData2.push(this.newEnergyHeatData.vgheCap3.toFixed(2));

          // alert(this.calcMessage);
        })
        .catch(() => {
          console.log('실패');
        });
    },
		mover() {
      this.helpBoxShow = true;
    },
    mout() {
      this.helpBoxShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.heatContentWrap {
  padding: 10px 0;
}
.heatContent {
  padding: 5px;
}
.vgheClass {
  display: inline-block;
  margin: 0px;
  font-weight: 600;
}
.heatEnergyTitle{
	display:inline-block;
}
.menuHelp{
	vertical-align:middle;
	img{
		width:20px;
	}
}
.heatEnergyExp{
	top: 55px;
	left: 170px;
	width:520px;
	line-height:28px;
}
</style>
