<template>
  <div>
    <div class="mb-2">
      <h3>▶ 층별개요</h3>
      <span style="color:red;"><b>※ 해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다.</b></span>
    </div>
		<div v-show="loading" class="text-center loadingArea" style="margin-top:15%;">
			<b-spinner class="m-5" label="Busy" variant="dark" />
		</div>
		<div v-show="!loading">
			<b-table
				responsive="sm"
				striped
				:fields="fields"
				:items="
					buildFloorData.map((v) => ({
						flrgbnm: v.flrgbnm,
						flrnonm: v.flrnonm,
						mainpurpsnm: v.mainpurpsnm,
						etcpurps: v.etcpurps,
						area: v.area,
					}))
				"
			/>
		</div>
  </div>
</template>
<script>
import { BTable, BSpinner } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BTable,
		BSpinner,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
			loading: true,
      fields: [
        {
          key: 'flrgbnm',
          label: '층구분',
        },
        {
          key: 'flrnonm',
          label: '층번호',
        },
        {
          key: 'mainpurpsnm',
          label: '층용도',
        },
        {
          key: 'etcpurps',
          label: '층 기타용도',
        },
        {
          key: 'area',
          label: '연면적(㎡)',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      buildFloorData: 'main/getBuildFloorData',
    }),
  },
  async created() {
    await this.$store.dispatch('main/FETCH_BUILD_FLOOR_DATA', {
      dataCode: 'buildFloorData',
      mgmBldPk: this.mgmBldPk,
    });
		this.loading = false;
  },
};
</script>
<style scoped>
/*.row-style{
  border: 1px solid #cccccc;
  border-radius: 3px;
  font-family: Helvetica Neue, Arial, sans-serif;
}*/
</style>
