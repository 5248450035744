<template>
  <div class="body-style">

    <energy-meter-sub-info-component
      :mgm-bld-pk="mgmBldPk"
      :meter-type="1"
    />
    <energy-meter-sub-info-component
      :mgm-bld-pk="mgmBldPk"
      :meter-type="2"
    />
    <!--    meterType  1: 일반표제부, 2: 총괄표제부-->
  </div>
</template>

<script>
import EnergyMeterSubInfoComponent from '@/views/building/EnergyUse/EnergyMeterSubInfoComponent'

export default {
  components: {
    EnergyMeterSubInfoComponent
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
<style scoped>

</style>
