export default {
  // Endpoints
  // loginEndpoint: '/auth/signin',
  // registerEndpoint: '/jwt/register',
  refreshEndpoint: '/api/auth/refreshToken',
  // logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  // tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  // cookieTokenKeyName: 'auth',
  // storageRefreshTokenKeyName: 'refreshToken',
  // storageUserDataKeyName: 'userData',
  // storageMenuDatakeyName: 'menuData',
  cookieAccessTokenKeyName: 'accessToken',
  cookieRefreshTokenKeyName: 'refreshToken',

  // jwt access token 만료 기간(30분) => 서버 설정과 동일
  accessTokenExpireTime: '60 * 60',
  // jwt refresh token 만료 기간(2주) => 서버 설정과 동일
  refreshTokenExpireTime: '60 * 60 * 24 * 14',
};
