import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import notice from './notice';
import filter from './filter';
import main from './main';
import auth from './auth';
import admin from './admin';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    notice,
    filter,
    main,
    auth,
    admin,
  },
  plugins: [
    createPersistedState({
      paths: ['auth'],
    }),
  ],
  strict: process.env.DEV,
});
