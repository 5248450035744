<template>
  <div>
    <b-row>
      <b-col md="12">
        <h3 style="text-align: center;">
          <u>건물 정보 수정 제안</u>
        </h3>
        <b-form-group>
          <v-select id="mainPurpsCd" v-model="buildInfoEditOffer.suggestCd" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="cdNm" placeholder="선택" :options="commCodeData.SUGGEST_CD" />
          <b-form-textarea id="suggestText" v-model="buildInfoEditOffer.suggestText" :state="text.length <= 200" placeholder="제안 사항을 입력하세요." rows="6" style="margin-top: 10px;" />
        </b-form-group>
        <!--        <b-form-group
                  label="파일첨부"
                  label-for="h-file"
                  label-cols-md="3"
                  style="margin-top: 10px;"
                >
                  <b-form-file
                    v-model="file"
                    placeholder="파일찾기"
                    drop-placeholder="Drop file here..."
                    style="margin-top: 10px;"
                  />
                  <b-form-checkbox
                    id="checkbox-2"
                    name="checkbox-2"
                    value="Remember_me"
                    plain
                    style="margin-top: 10px; padding-left: 0;"
                  >
                    (필수) 이미지 저작권 동의
                  </b-form-checkbox>
                </b-form-group>-->
        <b-form-group label="이메일" label-for="h-email" label-cols-md="3" style="margin-top: 10px;">
          <!--          등록된 이미지는 제보 내용 확인을 위해 사용됩니다.-->
          <b-form-input id="h-email" v-model="buildInfoEditOffer.suggestEmail" type="email" placeholder="Email" style="margin-top: 10px;" />
          <div style="display: inline-flex;margin-left: -18px;margin-top: 10px;">
            <b-form-radio v-model="buildInfoEditOffer.suggestEmailYn" plain name="some-radios3" value="Y">
              개인정보 수집 동의
            </b-form-radio>
            <b-form-radio v-model="buildInfoEditOffer.suggestEmailYn" plain name="some-radios3" value="N">
              개인정보 수집 미동의
            </b-form-radio>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <div style="text-align: right">
      <BButton @click="handleSubmit">
        저장
      </BButton>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormTextarea, BFormInput, BFormRadio, BButton } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { mapGetters } from 'vuex';
import { utils } from '@/utils/commUtils';

export default {
  name: 'BuildInfoEditOffer',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormTextarea,
    BFormInput,
    BFormRadio,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: '',
      value: '',
      maxChar: 200,
      file: null,
    };
  },
  computed: {
    ...mapGetters({
      commCodeData: 'main/getCommCodeData',
      mainSelected: 'main/getSelectedCodeData',
      buildInfoEditOffer: 'main/getBuildInfoEditOffer',
    }),
  },
  created() {
    // 데이터 초기화
    this.$store.dispatch('main/FETCH_BUILD_EDIT_OFFER_RESET');

    // 공통코드 불러오기
    this.$store.dispatch('main/FETCH_COMM_CODE_DATA');
  },
  methods: {
    handleSubmit() {
      utils.fnConfirm(this, '등록하시겠습니까?', '').then(async (res) => {
        if (res.value) {
          // 사용자가 확인 눌럿을 경우
          // 유효성 검사
          if (this.buildInfoEditOffer.suggestCd.length === 0) {
            alert('관련정보를 선택해 주세요.');
            return;
          }

          if (this.buildInfoEditOffer.suggestText === '') {
            alert('제안사항을 입력해 주세요.');
            return;
          }

          const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

          if (this.buildInfoEditOffer.suggestEmail.match(regExp) == null) {
            alert('이메일 양식이 올바르지 않습니다.');
            return;
          }

          if (this.buildInfoEditOffer.suggestEmailYn === '') {
            alert('개인정보 수집 동의여부를 선택해 주세요.');
            return;
          }

          this.buildInfoEditOffer.mgmBldPk = this.mgmBldPk;
          await this.$store.dispatch('filter/SAVE_FILTER_EDIT_OFFER', this.buildInfoEditOffer);
          this.modalBuildInfoEditOffer = !this.modalBuildInfoEditOffer;

          alert('등록되었습니다.');

          // 초기화
          this.buildInfoEditOffer.suggestCd = '';
          this.buildInfoEditOffer.suggestText = '';
          this.buildInfoEditOffer.suggestEmail = '';
          this.buildInfoEditOffer.suggestEmailYn = '';
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
