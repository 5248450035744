// HTTP reqeust & response와 관련된 기본 설정을 해줌
// eslint-disable-next-line import/no-cycle

import { instanceWithAuth } from '@/api';

const config = {
  url_commCode: '/api/commCode/',
  url_auth: '/api/admin/',
};

const adminAxiosCall = {
  fetchAdminList(payload) {
    return instanceWithAuth.post(`${config.url_auth}getAdminList`, payload);
  },
  fetchAdminInfo(payload) {
    return instanceWithAuth.post(`${config.url_auth}getAdminInfo`, payload);
  },
  fetchAdminJoin(payload) {
    return instanceWithAuth.post(`${config.url_auth}setAdminJoin`, payload);
  },
  fetchAdminUpdate(payload) {
    return instanceWithAuth.post(`${config.url_auth}setAdminUpdate`, payload);
  },
  fetchAdminDelete(payload) {
    return instanceWithAuth.post(`${config.url_auth}setAdminDelete`, payload);
  },
  fetchAdminLoginHist(payload) {
    return instanceWithAuth.post(`${config.url_auth}getAdminLoginHist`, payload);
  },
  fetchAdminBuildHist(payload) {
    return instanceWithAuth.post(`${config.url_auth}getAdminBuildHist`, payload);
  },
  fetchAdminBookmarkHist(payload) {
    return instanceWithAuth.post(`${config.url_auth}getAdminBookmarkHist`, payload);
  },
};

// eslint-disable-next-line import/prefer-default-export
export { adminAxiosCall };
