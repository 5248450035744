import axios from 'axios';
import JwtService from '@/auth/jwt/jwtService';

const instance = axios.create({
  // timeout: 1000,
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json',
  },
});

instance.interceptors.response.use(
  (response) => {
    if (response.data) {
      return response;
    }
    // msgAlertBox(app, response.data.resMsg);
    throw new Error(response.data.resCd);
  },
  (error) => {
    console.error(error);
    // msgAlertBox(app, response.data.resMsg);
    return Promise.reject(error);
  },
);

const useJwt = new JwtService(axios, {});
const instanceWithAuth = useJwt.getAxiosWithAuth();

export { instance, instanceWithAuth };
