import router from '@/router';
import { cookie } from "@/utils/cookie";
import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axios, jwtOverrideConfig) {
      this.axiosIns = axios.create({
        // timeout: 1000,
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          ContentType: 'application/json',
        },
      });

      this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

      // Request Interceptor
      this.axiosIns.interceptors.request.use(
        (config) => {
          const accessToken = cookie.getAccessToken();

          // If token is present add it to request's Authorization Header
          if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = accessToken;
          }
          return config;
        },
        (error) => Promise.reject(error),
      );

      // Add request/response interceptor
      this.axiosIns.interceptors.response.use(
        (response) => {
          if (response.data) {
            return response;
          }
          // msgAlertBox(app, response.data.resMsg);
          throw new Error(response.data.resCd);
        },
        (error) => {
          const { config, response } = error;
          const originalRequest = config;

          if (response && response.status === 401) {
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true;

              this.refreshToken().then((res) => {
                if (res.data.resultFlag === false) {
                  this.isAlreadyFetchingAccessToken = false;
                  cookie.delAccessToken();
                  cookie.delRefreshToken();
                  router.replace({ path: 'auth-login' }).then(() => {
                  });
                } else {
                  this.isAlreadyFetchingAccessToken = false;
                  cookie.saveAccessToken(res.data.resultData.accessToken);

                  // this.axiosIns.headers.Authorization = r.data.resData.newAccessToken
                  this.onAccessTokenFetched(res.data.resultData.accessToken);
                }
              }).catch((e) => {
                console.error(e);
                router.replace({ path: 'auth-login' }).then(() => {
                  this.isAlreadyFetchingAccessToken = false;
                }).catch({});
              });
            }
            return new Promise((resolve) => {
              this.addSubscriber((accessToken) => {
                // Make sure to assign accessToken according to your response.
                // Check: https://pixinvent.ticksy.com/ticket/2413870
                // Change Authorization header
                // originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                originalRequest.headers.Authorization = `${accessToken}`;
                resolve(this.axiosIns(originalRequest));
              });
            });
          }

          console.error(error);
          // msgAlertBox(app, app.$t('message.error.requiredLogin'));

          return Promise.reject(error);
        },
      );
    }

    onAccessTokenFetched(accessToken) {
      this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
    }

    addSubscriber(callback) {
      this.subscribers.push(callback);
    }

    refreshToken() {
      return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
        refreshToken: cookie.getRefreshToken(),
      },
      {
        headers: {
          Authorization: cookie.getAccessToken(),
        },
      });
    }

    /**
     * 인증 처리 포함된 axios 반환
     * @returns 인증 처리 포함된 axios
     */
    getAxiosWithAuth() {
      return this.axiosIns;
    }
}
