<template>
  <div>
    <div class="mb-2">
      <h3>▶ 인허가 리스트</h3>
      <span style="color:red;"><b>※ 해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다.</b></span>
    </div>
    <div>
      <b-table striped responsive :items="items" :fields="fields" class="row-modal" />
      <div v-show="isData" class="noData">
        <p>데이터가 존재하지 않습니다.</p>
      </div>
      <div v-show="loading" class="text-center">
        <b-spinner class="m-5" label="Busy" variant="dark" />
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BSpinner } from 'bootstrap-vue';

export default {
  components: {
    BTable,
    BSpinner,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      isData: false,
      fields: [
        {
          key: 'opnsvcnm',
          label: '업종명',
        },
        {
          key: 'bplcnm',
          label: '상호명',
        },
        {
          key: 'sitewhladdr',
          label: '인허가 주소',
        },
        {
          key: 'uptaenm',
          label: '업태',
        },
      ],
      items: [],
    };
  },
  created() {
    // 인허가 정보 데이터
    this.$store
      .dispatch('main/FETCH_BUILD_LICENSE_DATA', {
        dataCode: 'buildLicenseData',
        mgmBldPk: this.mgmBldPk,
      })
      .then(() => {
        this.loading = false;
        this.items = this.$store.state.main.licenseList;
        if (this.items.length === 0) this.isData = true;
      })
      .catch(() => {
        console.error('오류');
      });
  },
};
</script>
<style lang="scss">
.row-modal {
  font-family: Helvetica Neue, Arial, sans-serif;
}
.noData {
  text-align: center;
  border: 1px solid #cccccc;
  border-top-width: 0;
  display: block;
  top: -15px;
  position: relative;
  padding-top: 15px;
}
</style>
