export default {
  // overlay flag
  getShowOverlay(state) {
    return state.showOverlay;
  },

  // 필터 정보 가져오기
  getFilterInfo(state) {
    return state.filterInfo;
  },

  // result flag
  getResult(state) {
    return state.result;
  },

  // 공통코드 데이터 설정
  getCommCodeData(state) {
    return state.commCodeData;
  },
  getFilterCommCodeData(state) {
    return state.filterCommCodeData;
  },

  // 시도 데이터 설정
  getSidoList(state) {
    return state.sidoList;
  },

  // 시군구 데이터 설정
  getSigunguList(state) {
    return state.sigunguList;
  },

  // 저장할 필터 정보
  getSaveFilterInfo(state) {
    return state.saveFilterInfo;
  },

  // 통계 데이터
  getData(state) {
    return state.data;
  },

  // 통계 요약 데이터
  getDataSummary(state) {
    return state.dataSummary;
  },

  // ####### 차트 관련 #######
  getBoxplotData(state) {
    const chartData = state.boxplotData;
    const result = [];
    const boxplotData = {
      type: 'boxPlot',
      data: [],
    }

    if (Object.keys(chartData).length !== 0) {
      chartData.forEach((element) => {
        const tempObj = {};
        tempObj.x = element.dynamicColumnNm;
        tempObj.y = [element.perc04, element.perc25, element.perc50, element.perc75, element.perc96];
        boxplotData.data.push(tempObj);
      })
    }
    result.push((boxplotData));
    return result;
  },

  // ###### 업종 팝업 관련 #####
  // 업종 정보 데이터
  getOpnsvcList(state) {
    return state.opnsvcList;
  },

  // 가져온 필터 정보
  getFilterFetchedInfo(state) {
    return state.filterFetchedInfo;
  }
};
