<template>
  <div>
    <div class="mb-2">
      <h3>▶ 건축물대장</h3>
      <span style="color:red;"><b>※ 해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다.</b></span>
    </div>
    <div class="table-padding buildBasicInfoRow">
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>대장종류</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].regstrkindnm }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>건축물명칭</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].dongnm ? buildBasicData[0].dongnm : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건축물명</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].bldnm ? buildBasicData[0].bldnm : '-' }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData ? buildBasicData[0].regstrkindnm : '' }} PK
          </b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].mgmbldpk }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>주소</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].plataddr }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>지상층수(층)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].grndflrcnt ? buildBasicData[0].grndflrcnt : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건물주용도</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].mainpurpsnm }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>지하층수(층)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].ugrndflrcnt ? buildBasicData[0].ugrndflrcnt : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건축면적(m²)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].archarea }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>연면적(m²)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].totarea }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건물높이(m)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].heit ? buildBasicData[0].heit : '-' }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>주부속구분</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].mainatchgbnm ? buildBasicData[0].mainatchgbnm : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건폐율(%)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].bcrat }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>부속건물면적(m²)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].atchbldarea }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>대지면적(m²)</b-form-group>
        </b-col>
        <b-col cols="10" class="cols-td">
          <b-form-group v-if="buildBasicData[0]">
            {{ buildBasicData[0].platarea }}
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalBldType: 'block',
    };
  },
  computed: {
    ...mapGetters({
      buildBasicData: 'main/getBuildBasicData',
    }),
  },
  updated() {
    if (this.buildBasicData.length > 0) {
      // 건물 정보 있을때만 업데이트
      this.modalBldType = JSON.stringify(this.buildBasicData[0].regstrkindcd === '1' ? 'none' : 'block');
    }
  },
  created() {
    // 페이지 생성 시 데이터 불러옴
    this.$store.dispatch('main/FETCH_BUILD_BASIC_DATA', {
      dataCode: 'buildBasicData',
      mgmBldPk: this.mgmBldPk,
    });
  },
};
</script>
<style>
/*
element.style  {
 --displayTab: modalBldType
}
.nav-justified {
  display: var(--displayTab);
}
*/
</style>
<style lang="scss" scoped>
.row-style {
  border: 1px solid #cccccc;
  border-radius: 3px;
  font-family: Helvetica Neue, Arial, sans-serif;
}

.cols-bg {
  background-color: #f3f2f7 !important;
  min-width: 120px;
  padding: 10px 10px;
}

.cols-td {
  min-width: 120px;
  padding: 10px 10px;
}

.table-padding {
  padding: 0px 11px 0px 11px;
}

.buildBasicInfoRow .row{
	flex-wrap:nowrap;
}
</style>
