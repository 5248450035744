<template>
  <div class="mt-1">
    <b-table responsive="sm" :items="items" :fields="fields" />
  </div>
</template>
<script>
import { BTable } from 'bootstrap-vue';

export default {
  components: {
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'grade_cls3_cd',
          label: '상세등급',
        },
        {
          key: 'grade_cls3_desc',
          label: '산정 조건 (맵핑 정확도가 높은 케이스)',
        },
      ],
      items: [],
    };
  },
  async mounted() {
    this.items = await this.$store.dispatch('main/FETCH_BUILD_GRADE_LIST');
  },
};
</script>
<style></style>
