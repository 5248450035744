import { adminAxiosCall } from '@api/admin';

export default {
  async FETCH_ADMIN_LIST({ commit }, payload) {
    const { data } = await adminAxiosCall.fetchAdminList(payload);
    if (data.resultFlag) {
      commit('SET_ADMIN_LIST', data.resultData);
    }
    return data;
  },

  async FETCH_ADMIN_INFO({ commit }, payload) {
    const { data } = await adminAxiosCall.fetchAdminInfo(payload);
    if (data.resultFlag) {
      commit('SET_ADMIN_INFO', data.resultData);
    }
    return data.resultData;
  },

  async FETCH_ADMIN_JOIN({ commit }, payload) {
    const { data } = await adminAxiosCall.fetchAdminJoin(payload);
    if (data.resultFlag) {
      commit('SET_ADMIN_JOIN', data.resultData);
    }
    return data;
  },

  async FETCH_ADMIN_UPDATE({ commit }, payload) {
    const { data } = await adminAxiosCall.fetchAdminUpdate(payload);
    if (data.resultFlag) {
      commit('SET_ADMIN_UPDATE', data.resultData);
    }
    return data;
  },
  async FETCH_ADMIN_DELETE({ commit }, payload) {
    const { data } = await adminAxiosCall.fetchAdminDelete(payload);
    if (data.resultFlag) {
      commit('SET_ADMIN_DELETE', data.resultData);
    }
    return data;
  },
  async FETCH_ADMIN_LOGIN_HIST({ commit }, payload) {
    const { data } = await adminAxiosCall.fetchAdminLoginHist(payload);
    if (data.resultFlag) {
      commit('SET_ADMIN_LOGIN_HIST', data.resultData);
    }
    return data;
  },
  async FETCH_ADMIN_BUILD_HIST({ commit }, payload) {
    const { data } = await adminAxiosCall.fetchAdminBuildHist(payload);
    if (data.resultFlag) {
      commit('SET_ADMIN_BUILD_HIST', data.resultData);
    }
    return data;
  },
  async FETCH_ADMIN_BOOKMARK_HIST({ commit }, payload) {
    const { data } = await adminAxiosCall.fetchAdminBookmarkHist(payload);
    if (data.resultFlag) {
      commit('SET_ADMIN_BOOKMARK_HIST', data.resultData);
    }
    return data;
  },
};
