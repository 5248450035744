<template>
	<div>
		<div class="topWrap">
			<div class="left">
				<div class="mb-2">
					<h3>▶ 에너지소비량 유사건물군 비교</h3>
					<span style="color:red;"><b>※ 해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다.</b></span>
				</div>
				<!-- <span class="sigunguTitle">{{ sigunguData.name }}</span> -->
				<b-row>
					<b-col md="11">
						<b-form-group
							label="[비교 지역]"
							label-for="referFigure"
							class="labelTitle"
						>
							<v-select
								id="sidoData"
								v-model="selected.sidoData"
								label="sidoNm"
								placeholder="선택"
								:options="sidoList"
								style="display:inline-block; margin-right:7px; width:49%;"
								@input="sidoChange($event)"
							/>
							<v-select
								id="referFigure"
								v-model="selected.sigunguData"
								label="sigunguNm"
								placeholder="선택"
								:options="sigunguList"
								style="display:inline-block; width:49%;"
								@input="getSimilarData($event)"
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="11">
						<b-form-group
							label="[참고수치]"
							label-for="referFigure"
							class="labelTitle"
						>
							<v-select
								id="referFigure"
								v-model="selected.referFigure"
								label="cdNm"
								placeholder="선택"
								:options="similarBuildingFilter.referFigure"
								@input="getSimilarData($event)"
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="11">
						<b-form-group
							label="[비교건물군 사용승인일]"
							label-for="useAprYY"
							class="labelTitle"
						>
							<v-select
								id="useAprYY"
								v-model="selected.insltStCd"
								label="cdNm"
								placeholder="선택"
								:options="insltStList"
								@input="getSimilarData($event)"
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="11">
						<b-form-group
							label="[비교건물군 건물용도]"
							label-for="mainPurpsCd"
							class="labelTitle"
						>
							<v-select
								id="mainPurpsCd"
								v-model="selected.mainPurpsCd"
								label="cdNm"
								placeholder="선택"
								:options="mainPurpsList"
								@input="getSimilarData($event)"
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="11">
						<b-form-group
							label="[비교건물군 에너지사용 통계년도]"
							label-for="useEngyYY"
							class="labelTitle"
						>
							<v-select
								id="useEngyYY"
								v-model="selected.useYyCd"
								label="cdNm"
								placeholder="선택"
								:options="commCodeData.USE_YY_CD"
								@input="changeAllData($event)"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
			<div class="right">
				<div v-show="loading" class="text-center loadingArea">
        	<b-spinner class="m-5" label="Busy" variant="dark" />
      	</div>
				<div v-show="!loading">
					<app-echart-bar
					:key="componentKey"
					:option-data="option"
				/>
				</div>
			</div>
		</div>
		<div class="similarBuildingArea" style="margin-top:30px;">
			<b-table-simple small responsive class-name="row-style">
				<colgroup>
					<col>
					<col>
				</colgroup>
				<b-thead head-variant="light">
					<b-tr>
						<b-th class="transform-style perc30">
							{{ similarBuilding.text }}
						</b-th>
						<b-th class="transform-style perc30">
							사용자 건물
						</b-th>
						<b-th class="transform-style perc30">
							결과 - <span class="red">"초과"</span><span class="blue">"미달"</span>
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr>
						<b-td>{{ similarBuilding.qty | makeComma }}</b-td>
						<b-td>{{ selectedUseQty | makeComma }}kWh/m²</b-td>
						<b-td :class="resultQty.color">
							{{ resultQty.qty | makeComma }}
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
	</div>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd, BRow, BCol, BFormGroup, BSpinner,
} from 'bootstrap-vue';
import { mainAxiosCall } from '@api/main';
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive';
import { utils } from "@/utils/commUtils";
import 'echarts/lib/component/title';
import 'echarts/lib/component/markPoint';
import EventBus from "@/utils/eventBus";

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BRow,
    BCol,
    BFormGroup,
    BSpinner,
    vSelect,
    AppEchartBar: () => import('@core/components/charts/echart/AppEchartBar.vue'),
  },
  directives: {
    Ripple,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      componentKey: 0,
      similarBuilding: { text: '', qty: '' },
      selectedUseQty: '',
      resultQty: { color: '', qty: '' },
      selected: { referFigure: { cdNm: '중위값', cdId: '1' },
        insltStCd: { cdNm: '~1981 (1980.12)', cdId: '1981' },
        mainPurpsCd: { cdNm: '업무시설', cdId: '14000' },
        useYyCd: { cdNm: '2019', cdId: '2019' },
				sidoData: { sidoNm: '', sidoId: '' },
				sigunguData: { sigunguNm: '전체', sigunguId: '0' },
      },
			sigunguList: [],
			mainPurpsList: [],
			insltStList: [],
      option: {},
    };
  },
  computed: {
    ...mapGetters({
      commCodeData: "main/getCommCodeData",
			sidoList: "main/getSidoList",
      similarBuildingFilter: "main/getSimilarBuildingFilter",
      mainSelected: 'main/getSelectedCodeData',
    }),
  },
  async created() {
    await this.getCommData();
    this.selected.useYyCd = this.mainSelected.useYyCd;
    const data = await this.getBuildingData();

		if (data.resultFlag) {
			await this.drawSelect(data.resultData);
			this.getSimilarData();
		} else {
			this.$swal({
				text: '비교를 위한 데이터가 충분하지 않아 에너지소비량 유사건물군 비교가 불가합니다.',
				icon: "warning",
        buttonsStyling: false,
				customClass: {
          confirmButton: 'btn btn-dark',
        },
			}).then(() => {
				EventBus.$emit("use-eventbus");
			});
		}
  },
  methods: {
    async getCommData() {
      //await this.$store.dispatch('main/FETCH_COMM_CODE_DATA');
      this.selected.useYyCd = { cdNm: this.commCodeData.USE_YY_CD[0].cdNm, cdId: this.commCodeData.USE_YY_CD[0].cdId };
			//await this.$store.dispatch("filter/FETCH_SIDO_LIST");
    },
    async getBuildingData() {
      const param = { mgmBldPk: this.mgmBldPk, useYy: this.selected.useYyCd.cdId };
      const data = await this.$store.dispatch('main/FETCH_SELECTED_SIMILAR_BUILDING_DATA', param);
			console.log("data=", data);

			if (data.resultFlag) {
				this.getSigunguList(data.resultData.si_do_id);
				this.selected.sidoData = { sidoId: data.resultData.si_do_id, sidoNm: data.resultData.sido_nm };
				this.selectedUseQty = data.resultData.use_qty != null ? data.resultData.use_qty : '- ';
			}

      return data;
    },
    async drawSelect(data) {
			this.insltStList = this.commCodeData.INSLT_ST_CD.map((v) => {
				if (v.cdId === data.inslt_st_cd) {
          this.selected.insltStCd = { cdNm: `${v.cdNm}(본 건물)`, cdId: v.cdId };
          return { cdNm: `${v.cdNm}(본 건물)`, cdId: v.cdId };
        }
        return v;
      });
			this.insltStList.unshift({ cdId: '0', cdNm: '전체' });
			this.mainPurpsList = this.commCodeData.MAIN_PURPS_CD.map((v) => {
        if (v.cdId === data.main_purps_cd) {
          this.similarBuilding.text = `유사시설(${v.cdNm})군 중위값`;
          this.selected.mainPurpsCd = { cdNm: `${v.cdNm}(본 건물)`, cdId: v.cdId };
          return { cdNm: `${v.cdNm}(본 건물)`, cdId: v.cdId };
        }
        return v;
      });
    },
    async getSimilarData() {
      //const param = { insltStCd: this.selected.insltStCd.cdId, mainPurpsCd: this.selected.mainPurpsCd.cdId, useYyCd: this.selected.useYyCd.cdId, sigunguId: this.sigunguData.id, sidoId: this.selected.sidoData.sidoId };
			this.loading = true;
			const param = { insltStCd: this.selected.insltStCd.cdId, mainPurpsCd: this.selected.mainPurpsCd.cdId, useYyCd: this.selected.useYyCd.cdId, sigunguId: this.selected.sigunguData.sigunguId, sidoId: this.selected.sidoData.sidoId };
      const data = await this.$store.dispatch('main/FETCH_SIMILAR_BUILDING_DATA', param);
      this.loading = false;
      this.componentKey += 1;
      let x1 = 0;
    	let x2 = 0;
      let markPoint1;
      const markPoint2 = this.selectedUseQty !== null ? `{a|사용자 건물\n${this.selectedUseQty.toLocaleString()}kWh/㎡}` : `{a|사용자 건물\n- kWh/㎡}`;

      if (data.length === 0) {
        this.similarBuilding.qty = '- ';
        this.resultQty.qty = '- ';
        this.option = [{ data: [] }];
        utils.fnAlert(this, '데이터가 존재하지 않습니다');
      } else {
        if (this.selected.referFigure.cdId === '1') {
          this.similarBuilding.qty = `${data[0].perc_50}kWh/m²`;
          markPoint1 = `{a|중위값\n${data[0].perc_50.toLocaleString()}kWh/㎡}`;
          this.similarBuilding.text = `${this.similarBuilding.text.substring(0, this.similarBuilding.text.length - 3)}중위값`;
        } else {
          this.similarBuilding.qty = `${data[0].d_avg}kWh/m²`;
          markPoint1 = `{a|평균값\n${data[0].d_avg.toLocaleString()}kWh/㎡}`;
          this.similarBuilding.text = `${this.similarBuilding.text.substring(0, this.similarBuilding.text.length - 3)}평균값`;
        }
        const similarQtyNum = Number(this.similarBuilding.qty.substring(0, this.similarBuilding.qty.length - 6));
        this.calcQty(similarQtyNum, this.selectedUseQty);

        x1 = this.calcCoord(data, similarQtyNum);
        x2 = this.calcCoord(data, this.selectedUseQty);
      }

      this.option = {
        title: {
          text: '단위면적 E사용량(kWh/㎡)',
          textStyle: {
            color: 'black',
            fontSize: 14,
          },
          left: 'center',
          bottom: 0,
        },
        xAxis: [{
          type: "category",
          name: "kWh/㎡",
          data: data.map((v) => v.use_qty.toLocaleString()),
          nameTextStyle: {
            fontSize: 10,
          },
          axisPointer: {
            type: "shadow",
          },
          triggerEvent: true,
        }],
        yAxis: [{
          type: "value",
        }],
        series: [{
          type: 'bar',
          data: data.map((v) => v.cnt),
          markPoint: {
            data: [
              {
                type: 'custom',
                name: '중위값 표식1',
                xAxis: x1,
                yAxis: 0,
                // coord: [7, 0], // 표식을 표시할 좌표
                symbolSize: 130,
                label: {
                  show: true,
                  formatter () {
                    return markPoint1;
                  },
                  rich: {
                    a: {
                      fontSize: 12,
                      color: 'black',
                      fontWeight: 'bold',
                      align: 'center',
                    },
                  },
                },
                itemStyle: {
                  color: 'rgba(241,246,250,0.5)',
                  borderColor: 'rgb(153, 153, 51)',
                },
              },
              {
                type: 'custom',
                name: '사용자 건물',
                xAxis: x2,
                yAxis: 0,
                // coord: [0, 0], // 표식을 표시할 좌표
                symbolSize: 130,
                label: {
                  show: true,
                  formatter () {
                    return markPoint2;
                  },
                  rich: {
                    a: {
                      fontSize: 12,
                      color: 'black',
                      fontWeight: 'bold',
                      align: 'center',
                    },
                  },
                },
                itemStyle: {
                  color: 'rgba(255,255,250,0.5)',
                  borderColor: 'rgb(0, 0, 0)',
                },
              },
            ],
          },
        }],
      };
			this.loading = false;
    },
    async changeAllData() {
      this.getBuildingData();
      this.getSimilarData();
    },
    calcQty(similarQty, userQty) {
      const max = Math.max(similarQty, userQty);
      const min = Math.min(similarQty, userQty);

      this.resultQty.color = max === similarQty ? 'blue' : 'red';
      this.resultQty.qty = !Number.isNaN(max - min) ? `${(max - min).toFixed(2)}kWh/㎡` : `- kWh/㎡`;
    },
    calcCoord(data, referVal) {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].use_qty > referVal) {
          return i - 1;
        }
      }
      return data.length - 1;
    },
		sidoChange(event) {
			this.getSigunguList(event.sidoId);
			this.getSimilarData();
		},
		async getSigunguList(sidoId) {
			const sigunguData = await mainAxiosCall.fetchSigunguList(sidoId);

			this.selected.sigunguData = { sigunguNm: '전체', sigunguId: '0' };
			this.sigunguList = [{ sigunguNm: '전체', sigunguId: 0 }];

			sigunguData.data.resultData.forEach(v => {
				const sigungu = { sigunguId: v.sigunguId, sigunguNm: v.sigunguNm };
				this.sigunguList.push(sigungu);
			})
		},
  },
};

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.topWrap{
	display:flex;
}
.left{
	display:inline-block;
	width:45%;
}
.right{
	display:inline-block;
	width:55%;
}
.similarBuildingArea .table{
	text-align:center;
}
.table{
	th{border: 1px solid #ebe9f1;}
	td{border: 1px solid #cccccc;}
 }
.perc30{
	width:30%;
}
.sigunguTitle{
	display:block;
	margin:10px 0px;
	font-size: 20px;
	font-weight: bold;
}
.labelTitle{
	font-weight: bold;
	div{
		font-weight: normal;
	}
}
.loadingArea{
	position:relative;
	top:40%;
}
</style>
