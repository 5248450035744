import getters from "@/store/auth/getters";
import actions from '@/store/auth/actions';
import mutations from '@/store/auth/mutations';
import state from '@/store/auth/state';

export default {
  namespaced: true, // 모듈일 경우에는 namespaced를 true로 반드시 설정해야 한다.
  state,
  getters,
  mutations,
  actions,
};
