import { cookie } from '@/utils/cookie';

export default {
  SET_AUTH_JOIN(state, payload) {
    state.formData = payload;
  },
  SET_IS_AUTHENTICATED(state, payload) {
    state.isAuthenticated = payload;
  },
  SET_ROLE_ID(state, payload) {
    state.roleId = payload;
  },
  SET_USR_ID(state, payload) {
    state.usrId = payload;
  },
  SET_CLEAR_DATA(state) {
    state.roleId = '';
    state.usrId = '';
    state.formData = {};
    state.isAuthenticated = false;
    cookie.delAccessToken();
    cookie.delRefreshToken();
  },
  SET_AUTH_INFO(state, payload) {
    state.formData = payload;
    // state.formData.username = payload.username;
  },
};
